import { db, callApi } from 'services/config'
import { removeCookie, getCookie, setCookie } from 'services/cookie'
import { log } from 'services/util/log'
import { Track, Identify } from 'services/analytics'
import { ACCESS_TOKEN, USER_TYPE } from 'services/constants'

export const setAccessHeaders = req => {
  const token = getCookie(ACCESS_TOKEN, req)

  if (token) {
    db.defaults.headers.common['X-ACCESS-TOKEN'] = token
  } else {
    delete db.defaults.headers.common['X-ACCESS-TOKEN']
  }
}

export const getCurrentUser = async (req, res) => {
  let currentUser = null
  const userType = getCookie(USER_TYPE, req)

  try {
    currentUser = await callApi('GET', `/${userType === 'provider' ? 'provider' : 'user'}/me`)

    if (!currentUser) {
      removeCookie(ACCESS_TOKEN, res)
    }
  } catch (err) {
    log('Failed to get current user', 'error', { error: err })
    removeCookie(ACCESS_TOKEN, res)
  }

  return currentUser
}

export const isAuthenticated = req => !!getCookie(ACCESS_TOKEN, req)

const getAnonymousId = () => global.analytics?.user?.()?.anonymousId?.()

export const signInUsingEmail = async (email, password) => {
  let result = {}

  try {
    const data = await callApi('POST', '/authenticate/user', null, {
      email,
      password,
      anonymous_id: getAnonymousId(),
    })

    setCookie(ACCESS_TOKEN, data.access_token.access_token)
    setCookie(USER_TYPE, 'candidate')

    result = {
      data: data.user,
      message: null,
    }

    // 4/28/2023: deprecated segment user identification
    // Identify.user(data.user)
  } catch (err) {
    log('Failed to sign in using email', 'error', { error: err })

    result = {
      data: null,
      message: err.message,
    }

    Track.loginError(err.message, 'email', 'candidate')
  }

  return result
}

export const signInAsProvider = async (email, password) => {
  let result = {}

  try {
    const data = await callApi('POST', '/authenticate/provider', null, {
      email,
      password,
      anonymous_id: getAnonymousId(),
    })

    setCookie(ACCESS_TOKEN, data.access_token.access_token_id)
    setCookie(USER_TYPE, 'provider')

    result = {
      data: data.provider,
      message: null,
    }
  } catch (err) {
    log('Failed to sign in as provider', 'error', { error: err })

    result = {
      data: null,
      message: err.message,
    }

    Track.loginError(err.message, 'email', 'provider')
  }

  return result
}

export const signOutUser = () => {
  callApi('DELETE', '/authenticate').catch(err => {
    log('Failed  to sign out user', 'error', { error: err })
  })

  global?.analytics?.reset()
  removeCookie(ACCESS_TOKEN)
}

export const registerUsingEmail = async values => {
  let result = {}

  try {
    const data = await callApi('POST', '/user', null, {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      subscribed_newsletter: values.subscribed_newsletter,
      gender: values.gender,
      dob_year: values.dob_year,
      password: values.password,
      anonymous_id: getAnonymousId(),
    })

    Track.signupSuccess('aedit')

    const token = data.access_token.access_token

    setCookie(ACCESS_TOKEN, token)
    setCookie(USER_TYPE, 'candidate')

    result = {
      data: data.user,
      message: null,
    }

    // 4/28/2023: deprecated segment user identification
    // Identify.user(data.user, true)
  } catch (err) {
    log('Failed to register using email', 'error', { error: err })

    result = {
      message: err.message,
      data: null,
    }

    Track.signupError(err.message, 'aedit')
  }

  return result
}

export const authWithGoogle = async token => {
  let result = {}

  try {
    const { access_token, user, is_new_user } = await callApi(
      'POST',
      '/authenticate/user/google',
      null,
      {
        token,
        anonymous_id: getAnonymousId(),
      }
    )

    if (is_new_user) {
      Track.signupSuccess('google')
    }

    result = {
      message: 'Success',
      user,
    }

    setCookie(ACCESS_TOKEN, access_token.access_token)
    setCookie(USER_TYPE, 'candidate')

    // 4/28/2023: deprecated segment user identification
    // Identify.user(user)
  } catch (err) {
    log('Failed to authenticate with google', 'error', { error: err })

    result = {
      message: err.message,
      data: null,
    }

    Track.signupError(err.message, 'google')
  }

  return result
}

export const authWithApple = async params => {
  let result = {}

  try {
    const { access_token, user, is_new_user } = await callApi(
      'POST',
      '/authenticate/user/apple',
      null,
      {
        ...params,
        anonymous_id: getAnonymousId(),
      }
    )

    if (is_new_user) {
      Track.signupSuccess('apple')
    }

    setCookie(ACCESS_TOKEN, access_token.access_token)
    setCookie(USER_TYPE, 'candidate')

    result = {
      message: 'Success',
      user,
    }

    // 4/28/2023: deprecated segment user identification
    // Identify.user(user)
  } catch (err) {
    log('Failed to authenticate with apple', 'error', { error: err })

    result = {
      message: err.message,
      data: null,
    }

    Track.signupError(err.message, 'apple')
  }

  return result
}

export const updateUser = async values => {
  let result = {}
  let form = new FormData()

  // props not needed for an update
  const ignored = ['payment_methods']

  // serialize user object
  for (const [key, value] of Object.entries(values)) {
    if (value == null || ignored.includes(key)) {
      continue
    } else if (value instanceof File) {
      // profile picture
      form.append(key, value)
    } else if (typeof value === 'object') {
      // location, referral_code, notification_settings
      form.append(key, JSON.stringify(value))
    } else {
      form.append(key, value)
    }
  }

  try {
    const data = await callApi('PUT', '/user/me', null, form)

    result = {
      message: 'Success',
      data,
    }

    // 4/28/2023: deprecated segment user identification
    // Identify.user(data)
  } catch (err) {
    log('Failed to update current user', 'error', { error: err })

    result = {
      message: err.message,
      data: null,
    }
  }
  return result
}

// type can only be 'user' or 'provider'
export const forgotPassword = async (email, type) => {
  let result = {}

  if (!type) {
    return result
  }

  try {
    const data = await callApi('POST', `/${type}/forgot-password`, null, { email })

    result = {
      message: 'Success',
      data,
    }
  } catch (err) {
    log('Failed to request new password', 'error', { error: err })

    result = {
      message: err.message,
      data: null,
    }
  }

  return result
}

export const resetPassword = async (password, token, type = 'user') => {
  let result = {}

  try {
    const data = await callApi('POST', `/${type}/reset-password`, null, {
      password,
      token,
    })

    result = {
      message: 'Success',
      data,
    }
  } catch (err) {
    log('Failed to reset password', 'error', { error: err })
    result = {
      data: null,
      message: err.message,
    }
  }

  return result
}
