export class ApiError extends Error {
  constructor(arg, res = null) {
    const message = arg instanceof Error ? arg.message : arg
    super(message)

    if (arg instanceof Error) {
      this.error = arg
    }

    const config = res?.config

    this.request = {
      method: config?.method,
      url: config?.url,
      params: config?.params,
      data: config?.data ? Object.keys(config?.data) : undefined,
      headers: config?.headers ? Object.keys(config?.headers) : undefined,
      timeout: config?.timeout,
    }

    this.response = {
      error: res?.data?.error,
      status: res?.status,
      app_status: res?.headers?.['x-aedit-status-code'],
      content_type: res?.headers?.['content-type'],
    }
  }

  get meta() {
    return {
      request: this.request,
      response: this.response,
    }
  }
}
