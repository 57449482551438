import axios from 'axios'
import { log } from 'services/util/log'

/**
 * React Geocode Module
 *
 * @package react-geocode
 * @author  Pir Shukarulalh Shah <shuker_rashdi@hotmail.com>  (http://www.shukarullah.com)
 */
let API_KEY = null
const GOOGLE_API = 'https://maps.google.com/maps/api/geocode/json'

async function handleUrl(url) {
  try {
    const res = await axios.get(url) // throws on non-OK status codes
    const json = res.data

    if (json.status !== 'OK') {
      throw new Error(`Server returned status code ${json.status}`)
    }

    log(json)
    return json
  } catch (err) {
    log(`Failed to geocode. URL: ${url}`, 'warn', { error: err, url })
    throw err
  }
}

export default {
  /**
   *
   *
   * @param {string} apiKey
   */
  setApiKey() {
    API_KEY = process.env.GOOGLE_MAPS_API_KEY
  },

  /**
   *
   *
   * @param {string} lat
   * @param {string} lng
   * @param {string} [apiKey]
   * @returns {Promise}
   */
  async fromLatLng(lat, lng, apiKey) {
    if (!lat || !lng) {
      log('Provided coordinates are invalid', 'warn', { lat, lng })
      return Promise.reject(new Error('Provided coordinates are invalid'))
    }

    const latLng = `${lat},${lng}`
    let url = `${GOOGLE_API}?latlng=${encodeURI(latLng)}`

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY
      url += `&key=${API_KEY}`
    }

    return handleUrl(url)
  },

  /**
   *
   *
   * @param {string} address
   * @param {string} [apiKey]
   * @returns {Promise}
   */
  async fromAddress(address, apiKey) {
    if (!address) {
      log('Provided address is invalid', 'warn', { address })
      return Promise.reject(new Error('Provided address is invalid'))
    }

    let url = `${GOOGLE_API}?address=${encodeURI(address)}`

    if (apiKey || API_KEY) {
      API_KEY = apiKey || API_KEY
      url += `&key=${API_KEY}`
    }

    return handleUrl(url)
  },
}
