import cookie from 'js-cookie'
import { ACCESS_TOKEN } from './constants'
import { setAccessHeaders } from './auth'

export const setCookie = (key, value, res, options = {}) => {
  return process.browser
    ? setCookieFromBrowser(key, value, options)
    : setCookieFromServer(key, value, res, options)
}

export const setCookieFromBrowser = (key, value, options = {}) => {
  if (process.browser) {
    cookie.set(key, value, {
      path: '/',
      ...options,
    })

    if (key === ACCESS_TOKEN) {
      setAccessHeaders()
    }
  }
}

const setCookieFromServer = (key, value, res, options = {}) => {
  res.cookie(key, value, {
    path: '/',
    ...options,
  })
}

export const removeCookie = (key, res) => {
  return process.browser ? removeCookieFromBrowser(key) : removeCookieFromServer(key, res)
}

const removeCookieFromBrowser = key => {
  if (process.browser) {
    cookie.remove(key)

    if (key === ACCESS_TOKEN) {
      setAccessHeaders()
    }
  }
}

const removeCookieFromServer = (key, res) => {
  res.clearCookie(key)
}

export const getCookie = (key, req) => {
  return process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req)
}

const getCookieFromBrowser = key => {
  return cookie.get(key)
}

const getCookieFromServer = (key, req) => {
  if (!req.headers.cookie) {
    return undefined
  }

  const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`))

  if (!rawCookie) {
    return undefined
  }

  return rawCookie.split('=')[1]
}
