import axios from 'axios'
import { inTesting } from './env'
import {
  GOOGLE_MAPS_CALLBACK,
  GOOGLE_PLACES_CALLBACK,
  CYPRESS_META_STRIPE_KEY,
  ACCESS_TOKEN,
} from './constants'
import { getCookie } from './cookie'
import { ApiError } from './errors'

// creating new client is wrapped in a function to prevent loading unnecessary modules in browser
const createApiClient = () => {
  if (process.browser) {
    const instance = axios.create({ baseURL: '/api' })
    instance.interceptors.request.use(config => {
      // inject access token
      const token = getCookie(ACCESS_TOKEN)
      const tokenHeader = token ? { 'X-ACCESS-TOKEN': token } : {}
      return {
        ...config,
        headers: {
          'X-API-VERSION': 1,
          ...config.headers,
          ...tokenHeader,
        },
      }
    })

    return instance
  } else {
    const requestIp = require('request-ip')
    const instance = axios.create({ baseURL: process.env.API_URL })

    instance.interceptors.request.use(config => {
      // inject access token
      const token = getCookie(ACCESS_TOKEN, config)
      const tokenHeader = token ? { 'X-ACCESS-TOKEN': token } : {}

      // inject client ip
      const ip = requestIp.getClientIp(config)
      const ipHeader = ip ? { 'X-Browser-Ip': ip } : {}

      return {
        ...config,
        headers: {
          'X-API-KEY': process.env.API_KEY,
          'X-API-VERSION': 1,
          ...config.headers,
          ...tokenHeader,
          ...ipHeader,
        },
      }
    })

    return instance
  }
}

const db = createApiClient()
export { db }

export const callApi = async (method, url, params, data, headers, timeout = 5000) => {
  let config

  try {
    method = method.toLowerCase()
    params = params || {}
    config = {
      method,
      url,
      params,
      data,
      headers,
      timeout,
    }

    const res = await db.request(config)

    if (res.data.error) {
      const msg = res.data.error.message
      throw new ApiError(msg, res)
    }

    return res.data.data
  } catch (err) {
    if (err instanceof ApiError) {
      throw err
    }

    const res = { config, ...err.response }

    if (err.code === 'ECONNABORTED') {
      throw new ApiError('We are sorry, please try again later', res)
    }

    throw new ApiError(err, res)
  }
}

export function initEmbedly() {
  if (typeof window !== 'undefined') {
    const initEmbedly = (w, d) => {
      const id = 'embedly-platform'
      const n = 'script'

      if (!d.getElementById(id)) {
        w.embedly =
          w.embedly ||
          (() => {
            ;(w.embedly.q = w.embedly.q || []).push(arguments)
          })

        let e = d.createElement(n)
        e.id = id
        e.async = true
        e.src =
          (document.location.protocol === 'https:' ? 'https' : 'http') +
          '://cdn.embedly.com/widgets/platform.js'

        const s = d.getElementsByTagName(n)[0]
        s.parentNode.insertBefore(e, s)
      }
    }

    initEmbedly(window, document)
  }
}

export const setupGoogleMapsCallback = () => {
  // asynchronously loaded google maps requires this synchronization:
  // 1. google maps api starts loading
  // 2. react-places-autocomplete creates a callback function GOOGLE_PLACES_CALLBACK and waits
  // 3. google maps api is now ready so it invokes the callback GOOGLE_MAPS_CALLBACK
  // 4. react-places-autocomplete renders the PlacesAutocomplete component

  return `
    window['${GOOGLE_MAPS_CALLBACK}'] = function() {
      var callback = window['${GOOGLE_PLACES_CALLBACK}']
      if (typeof callback === 'function') {
        callback()
      }
    }
  `
}

export const setupStripe = async () => {
  const { loadStripe } = await import('@stripe/stripe-js')
  const stripe = await loadStripe(process.env.STRIPE_API_KEY)

  // support for testing... see module support/payments.js in tests
  if (inTesting) {
    window[CYPRESS_META_STRIPE_KEY] = stripe
  }

  return stripe
}

export function fetchGreenhouseData() {
  let res = axios
    .get(`https://boards-api.greenhouse.io/v1/boards/aedit/jobs?content=true`)
    .then(res => res.data.jobs)
    .catch(err => {
      throw err
    })
  return res
}
