import { getLocationFromIp } from 'services/location'

//
// SEGMENT
//
const trackEvent = (name, props) => {
  global.analytics?.track?.(name, {
    ...props,
    platform: 'web',
  })
}

export const Identify = {
  provider(id, providerDetails = {}) {
    let identifyObject = {
      application: 'web',
      email: providerDetails.email,
      id: providerDetails.id,
      firstName: providerDetails.first_name,
      lastName: providerDetails.last_name,
      userType: providerDetails.userType,
      phone: providerDetails.phone,
      salutation: providerDetails.salutation,
      profile_picture: providerDetails.profile_picture,
      profile_picture_original: providerDetails.profile_picture_original,
      completed_signup: providerDetails.completed_signup,
      role_name: providerDetails.role_name,
      primary_practice: providerDetails.primary_practice,
    }
    global.analytics?.identify?.(id, identifyObject)
  },

  byEmail(email) {
    global.analytics?.identify?.({
      email,
    })
  },

  async user(user, signUp = false) {
    // must fire client side
    const location = await getLocationFromIp()
    const notifications = user?.notification_settings?.map(notification => {
      const { channel, type, value } = notification

      return { channel, type, value }
    })

    let identifyObject = {
      platform_registered: signUp ? 'web' : null,
      application: 'web',
      avatar: user.profile_picture,
      birthday: user.dob,
      phone: user.phone,
      // concerns: [],  we don't have this
      // interested_areas: [], we don't have this either
      email: user.email,
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      gender: user.gender,
      location,
      notifications,
      ssoSource: user?.sso?.sso_provider ?? 'none',
      userType: 'candidate',
      subscriptions: (user?.subscriptions ?? []).map(
        ({ entitlement_code_id, expiration_date, external_source, group_id, product_id }) => ({
          entitlement_code_id: +entitlement_code_id, // reports as int instead of string
          expiration_date,
          external_source,
          group_id,
          product_id,
        })
      ),
    }

    // get rid of null/undefined/'' values on userObject
    for (let [key, value] of Object.entries(identifyObject)) {
      if (!value) {
        delete identifyObject[key]
      }
    }

    global.analytics?.identify?.(user.id, identifyObject)
  },

  anon(traits = {}) {
    global.analytics?.identify?.(traits)
  },
}

export const Track = {
  aboutPageLoaded() {
    trackEvent('About_Page_Loaded', {
      nonInteraction: 1,
    })
  },

  aeditorAppPageLoaded() {
    trackEvent('Aeditor_App_Page_Loaded', {
      nonInteraction: 1,
    })
  },

  // aeditorAppPageTryClicked() {
  //   trackEvent('Aeditor_App_Page_Try_Clicked')
  // },

  aeditorMorphingApplied() {
    trackEvent('Aeditor_Morphing_Applied')
  },

  providerLandingLoaded() {
    trackEvent('Provider_Page_Loaded', {
      nonInteraction: 1,
    })
  },

  aeditionLoaded() {
    trackEvent('Aedition_Homepage_Loaded', {
      nonInteraction: 1,
    })
  },

  searchProcedures() {
    trackEvent('Search_Procedures_Loaded', { nonInteraction: 1 })
  },

  beautyBoardPageLoaded() {
    trackEvent('Beauty_Board_Page_Loaded', {
      nonInteraction: 1,
    })
  },

  whiteLabelPageLoaded() {
    trackEvent('White_Label_Loaded', {
      nonInteraction: 1,
    })
  },

  providerSearchPageLoaded({ city, state, country, query }) {
    trackEvent('Provider_Search_Page_Loaded', {
      nonInteraction: 1,
      user_location_city: city,
      user_location_state: state,
      user_location_country: country,
      query_params: query,
    })
  },

  contactPageLoaded() {
    trackEvent('Contact_Us_Loaded', { nonInteraction: 1 })
  },

  contactUsClicked() {
    trackEvent('Contact_Us_Clicked')
  },

  forgotPasswordLoaded() {
    trackEvent('Forgot_Password_Loaded', { nonInteraction: 1 })
  },

  providerAgreementLoaded() {
    trackEvent('Provider_Agreement_Loaded', { nonInteraction: 1 })
  },

  procedureTranscriptionLoaded({ slug, id }) {
    trackEvent('Procedure_Transcription_Loaded', {
      slug,
      id,
    })
  },

  consultationConfirmed({
    id,
    first_name,
    last_name,
    is_instant_booking,
    has_aedits,
    has_uploads,
    is_video_consultation,
    communication_method,
    consultation_id,
    payment_method,
  }) {
    trackEvent('Consultation_Requested', {
      provider_id: id,
      provider_first_name: first_name,
      provider_last_name: last_name,
      is_instant_booking,
      payment_method,
      has_aedits,
      has_uploads,
      is_video_consultation,
      communication_method,
      consultation_id,
    })
  },

  signupLoaded() {
    trackEvent('Signup_Login_Loaded', { nonInteraction: 1 })
  },

  signupCompleteLoaded() {
    trackEvent('Signup_Complete_Loaded', { nonInteraction: 1 })
  },

  ProviderBeforeAftersLoaded({ slug, id }) {
    trackEvent('Provider_Profile_Before_After_Loaded', {
      slug,
      id,
      nonInteraction: 1,
    })
  },

  newToAeditClicked() {
    trackEvent('Connect_With_Email_Clicked')
  },

  appleButtonClicked() {
    trackEvent('Connect_With_Apple_Clicked')
  },

  googleButtonClicked() {
    trackEvent('Connect_With_Google_Clicked')
  },

  candidateLoginSignupClicked() {
    trackEvent('Candidate_Login_Signup_Clicked')
  },

  providerLoginClicked() {
    trackEvent('Provider_Login_Clicked')
  },

  loginClicked() {
    trackEvent('Login_Clicked')
  },

  openInAppClicked(data) {
    trackEvent('Open_In_App_Clicked', data)
  },

  openInAppLoaded(data) {
    trackEvent('Open_In_App_Loaded', data)
  },

  providerPortalLoaded() {
    trackEvent('PPM_Profile_Loaded', { nonInteraction: 1 })
  },

  ppmUpcomingConsultationsLoaded() {
    trackEvent('PPM_Upcoming_Consultations_Loaded', { nonInteraction: 1 })
  },

  ppmConsultationHistoryLoaded() {
    trackEvent('PPM_Consultation_History_Loaded', { nonInteraction: 1 })
  },

  ppmPiLoaded() {
    trackEvent('PPM_PI_Loaded', { nonInteraction: 1 })
  },

  ppmSpecialtiesLoaded() {
    trackEvent('PPM_Specialties_Loaded', { nonInteraction: 1 })
  },

  ppmProceduresLoaded() {
    trackEvent('PPM_Procedures_Loaded', { nonInteraction: 1 })
  },

  ppmContactLoaded() {
    trackEvent('PPM_Contact_Info_Loaded', { nonInteraction: 1 })
  },

  ppmSettingsLoaded() {
    trackEvent('PPM_Account_Settings_Loaded', { nonInteraction: 1 })
  },

  ppmEducationLoaded() {
    trackEvent('PPM_Education_Loaded', { nonInteraction: 1 })
  },

  ppmStateLicenseLoaded() {
    trackEvent('PPM_State_License_Loaded', { nonInteraction: 1 })
  },

  ppmBoardCertificationLoaded() {
    trackEvent('PPM_Board_Certification_Loaded', { nonInteraction: 1 })
  },

  ppmAffiliationsLoaded() {
    trackEvent('PPM_Affiliations_Loaded', { nonInteraction: 1 })
  },

  ppmHeroImagesLoaded() {
    trackEvent('PPM_Hero_Images_Loaded', { nonInteraction: 1 })
  },

  ppmPersonalInformationLoaded() {
    trackEvent('PPM_PI_Loaded', { nonInteraction: 1 })
  },

  providerBeforeAfterDetailLoaded({ procedure_id, procedure_name, provider_id, provider_name }) {
    trackEvent('Provider_Before_After_Detail_Loaded', {
      procedure_id,
      procedure_name,
      provider_id,
      provider_name,
    })
  },

  providerAlbumsLoaded({ procedure_id, procedure_name, provider_id, provider_name }) {
    trackEvent('Provider_Albums_Loaded', {
      procedure_id,
      procedure_name,
      provider_id,
      provider_name,
    })
  },

  providerAlbumsClicked({ procedure_id, procedure_name, provider_id, provider_name }) {
    trackEvent('Provider_Albums_Clicked', {
      procedure_id,
      procedure_name,
      provider_id,
      provider_name,
    })
  },

  beforeAfterLoaded() {
    trackEvent('PPM_Before_And_After_Loaded', { nonInteraction: 1 })
  },

  beforeAfterSaved() {
    trackEvent('PPM_Before_And_After_Saved', { nonInteraction: 1 })
  },

  ppmPracticeLoaded() {
    trackEvent('PPM_Practice_Loaded')
  },

  ppmPublicationsLoaded() {
    trackEvent('PPM_Publications_Loaded')
  },

  ppmQaLoaded() {
    trackEvent('PPM_QA_Loaded')
  },

  ppmSaved({ section } = {}) {
    trackEvent('PPM_Saved', { section })
  },

  waitingRoomLoaded(consultation_id) {
    trackEvent('PPM_Video_Waiting_Room_Loaded', { consultation_id })
  },

  myProfileLoaded() {
    trackEvent('My_Profile_Loaded', { nonInteraction: 1 })
  },

  myProfileUpdated() {
    trackEvent('My_Profile_Updated')
  },

  myAeditsLoaded() {
    trackEvent('My_Aedits_Loaded')
  },

  myPaymentsLoaded() {
    trackEvent('My_Payments_Loaded')
  },

  myConsultationsLoaded() {
    trackEvent('My_Consultations_Loaded')
  },

  myConsultationsVideoJoinClicked() {
    trackEvent('My_Consultations_Video_Join_Clicked')
  },

  addPaymentLoaded() {
    trackEvent('Add_Payment_Loaded')
  },

  paymentMethodAddedSuccess() {
    trackEvent('Payment_Method_Added_Success', {
      section: 'candidates',
    })
  },

  paymentMethodAddedFailure(err) {
    trackEvent('Payment_Method_Added_Failure', {
      section: 'candidates',
      err,
    })
  },

  creditCardSubmissionFailed(err) {
    trackEvent('CC_Submission_Failed', {
      reason: err.message,
      page: window.location.pathname,
      err,
    })
  },

  signupSuccess(method) {
    trackEvent('Signup_Success', { method })
  },

  signupError(reason, method) {
    trackEvent('Signup_Failed', { reason, method, nonInteraction: 1 })
  },

  signupStepLoaded(step) {
    trackEvent('Signup_Step_Loaded', { step, nonInteraction: 1 })
  },

  loginError(reason, method, type) {
    trackEvent('Login_Failed', { reason, method, type, nonInteraction: 1 })
  },

  consultationFailed(err, { id, is_instant_booking, reason }) {
    trackEvent('Consultation_Failed', {
      err,
      provider_id: id,
      is_instant_booking,
      nonInteraction: 1,
      reason,
    })
  },

  providerAgreementSubmitted(obj = {}) {
    trackEvent('Provider_Agreement_Submitted', obj)
  },

  providerConsultationStep({
    id,
    first_name,
    last_name,
    is_instant_booking,
    step,
    communication_method,
    accept_video_consultation,
  }) {
    trackEvent('Book_A_Provider_Step_Loaded', {
      provider_id: id,
      provider_first_name: first_name,
      provider_last_name: last_name,
      is_instant_booking,
      step_number: step,
      page: window.location.pathname,
      communication_method,
      accept_video_consultation,
      nonInteraction: 1,
    })
  },

  providerProfileClicked({ id, slug, source }) {
    trackEvent('Provider_Profile_Clicked', {
      profile_id: id,
      profile_slug: slug,
      source: source,
    })
  },

  providerProfileModalOpened({ type }) {
    trackEvent('Provider_Profile_Modal_Opened', {
      type,
    })
  },

  providerFilterApplied(filters) {
    trackEvent('Provider_Filter_Applied', {
      filters,
    })
  },

  providerSearchApplied(term) {
    trackEvent('Provider_Search_Applied', {
      search_term: term,
    })
  },

  claimProfileClicked({ source, salutation, first_name, last_name }) {
    trackEvent('Provider_Claim_Clicked', {
      source,
      salutation,
      first_name,
      last_name,
    })
  },

  beautyBoardMemberClicked(id) {
    trackEvent('Beauty_Board_Member_Clicked', {
      id,
    })
  },

  homepageLoaded() {
    trackEvent('Homepage_Loaded', { nonInteraction: 1 })
  },

  directoryPageLoaded(type) {
    trackEvent('Directory_Page_Loaded', {
      page: window.location.pathname,
      type,
      nonInteraction: 1,
    })
  },

  searchClicked() {
    trackEvent('Search_Clicked', {
      page: window.location.pathname,
    })
  },

  searchQueryClicked(query) {
    trackEvent('Search_Applied', {
      page: window.location.pathname,
      search_term: query,
    })
  },

  providerPageLoaded({ id, name, slug, claimed }) {
    trackEvent('Provider_Profile_Loaded', {
      id,
      name,
      slug,
      claimed,
      nonInteraction: 1,
    })
  },

  procedureDetailLoaded(trackData = {}) {
    // segment
    trackEvent('Procedure_Detail_Page_Loaded', {
      ...trackData,
      page: window.location.pathname,
      nonInteraction: 1,
    })
  },

  collectionLoaded(id, name) {
    trackEvent('Aedition_Collection_Loaded', {
      page: window.location.pathname,
      collection_id: id,
      collection_name: name,
      nonInteraction: 1,
    })
  },

  articleClicked(id, title, slug) {
    trackEvent('Aedition_Article_Clicked', {
      page: window.location.pathname,
      articleId: id,
      articleTitle: title,
      slug,
    })
  },

  articleLoaded(id, title, tags, has_photos = false) {
    trackEvent('Aedition_Article_Loaded', {
      page: window.location.pathname,
      articleId: id,
      articleTitle: title,
      tags,
      has_photos,
      nonInteraction: 1,
    })
  },

  aeditionArticleShared(id, tags, channel) {
    trackEvent('Aedition_Article_Shared', {
      page: window.location.pathname,
      channel,
      id,
      tags,
    })
  },

  mediaPlayerInteraction(id, slug) {
    trackEvent('Multimedia_Player_Started', {
      page: window.location.pathname,
      name: slug,
      id,
    })
  },

  errorPageLoaded(error) {
    trackEvent('Error_Page_Loaded', {
      page: window.location.pathname,
      error,
      nonInteraction: 1,
    })
  },

  procedureClicked(id, name) {
    trackEvent('Procedure_Clicked', {
      page: window.location.pathname,
      id,
      name,
    })
  },

  providerSignupSubmitted({ firstName, lastName, userType, specialty, userId }) {
    trackEvent('Provider_Signup_Submitted', {
      page: window.location.pathname,
      first_name: firstName,
      last_name: lastName,
      user_type: userType,
      specialty,
      userId,
    })
  },

  whiteLabelRequestSubmitted({ first_name, last_name, user_type, userId }) {
    trackEvent('White_Label_Request_Submitted', {
      page: window.location.pathname,
      first_name,
      last_name,
      user_type,
      userId,
    })
  },

  whiteLabelRequestFailed(error) {
    trackEvent('White_Label_Request_Failed', {
      page: window.location.pathname,
      error,
      nonInteraction: 1,
    })
  },

  newsletterSignupSubmitted(email) {
    trackEvent('Newsletter_Signup_Submitted', {
      page: window.location.pathname,
      email,
    })
  },

  providerPracticeSubmitted() {
    trackEvent('Provider_Practice_Submitted', {
      page: window.location.pathname,
    })
  },

  authorPageLoaded() {
    trackEvent('Author_Page_Loaded', {
      page: window.location.pathname,
      nonInteraction: 1,
    })
  },

  appDownloadClicked() {
    trackEvent('App_Download_Clicked', {
      page: window.location.pathname,
    })
  },

  textMeTheAppClicked(entity_type) {
    trackEvent('Text_Me_The_App_Clicked', {
      page: window.location.pathname,
      entity_type,
    })
  },

  textMeTheAppFailed(error) {
    trackEvent('Text_Me_The_App_Failed', {
      page: window.location.pathname,
      error,
      nonInteraction: 1,
    })
  },

  textMeTheAppModalLoads() {
    trackEvent('Text_Me_The_App_Modal_Loads', {
      page: window.location.pathname,
      nonInteraction: 1,
    })
  },

  practiceProfilePageLoaded(id, slug) {
    trackEvent('Practice_Profile_Page_Loaded', {
      id,
      slug,
      nonInteraction: 1,
    })
  },

  concernPageLoaded({ id, slug, areas }) {
    trackEvent('Concern_Page_Loaded', {
      id,
      slug,
      areas,
      nonInteraction: 1,
    })
  },
  concernIndexLoaded() {
    trackEvent('Concern_Index_Page_Loaded', {
      nonInteraction: 1,
    })
  },
  concernCardClicked() {
    trackEvent('Concern_Index_Page_Card_Clicked')
  },

  helpfulButtonClicked({ entity_type, id, action }) {
    trackEvent('Helpful_Clicked', {
      page: window.location.pathname,
      entity_type,
      id,
      action,
    })
  },

  categoriesIndexLoaded() {
    trackEvent('Aedition_Topics_Index_Loaded', {
      nonInteraction: 1,
    })
  },

  categoryPageLoaded() {
    const page = window.location.pathname
    const slug = page.split('/')[page.split('/').length - 1]
    trackEvent('Aedition_Topic_Page_Loaded', { page, slug, nonInteraction: 1 })
  },

  reachedHiddenPage() {
    trackEvent('User_Reached_Hidden_Page', {
      page: window.location.pathname,
    })
  },

  socialClicked({ social_network }) {
    trackEvent('Social_Clicked', {
      page: window.location.pathname,
      social_network,
    })
  },

  providerProfileSocialClicked({ id, name, slug, social_network }) {
    trackEvent('Provider_Profile_Social_Clicked', {
      id,
      name,
      slug,
      social_network,
    })
  },

  bookingFlowOpened({ slug, page_type, pos, provider_id }) {
    trackEvent('Book_Clicked', {
      page: window.location.pathname,
      slug,
      page_type,
      pos,
      provider_id,
    })
  },

  careerPageLoaded() {
    trackEvent('Career_Page_Loaded', {
      nonInteraction: 1,
    })
  },

  cardViewProviderClicked(provider) {
    trackEvent('Card_View_Provider_Clicked', {
      page: window.location.pathname,
      firstName: provider.first_name,
      lastName: provider.last_name,
      id: provider.id,
    })
  },

  providerCardSurfaced(id) {
    trackEvent('Provider_Card_Surfaced', {
      page: window.location.pathname,
      id,
    })
  },

  consultationRequestModalClosed(provider = {}) {
    trackEvent('Book_A_Provider_Exit_Confirmed', {
      provider_id: provider.id,
      provider_first_name: provider.first_name,
      provider_last_name: provider.last_name,
    })
  },

  zeroProviderResults(filters) {
    trackEvent('Provider_Feed_Loaded_Zero_Results', {
      filters,
    })
  },

  gatedContentClicked({ source, provider_id, procedure_id }) {
    trackEvent('Gated_Content_Clicked', {
      type: source,
      page: window.location.pathname,
      provider_id: provider_id,
      procedure_id: procedure_id,
    })
  },

  favoriteClicked(vals) {
    trackEvent('Favorite_Clicked', {
      ...vals,
      page: window.location.pathname,
    })
  },

  pressPageLoaded() {
    trackEvent('Press_Page_Loaded', {
      nonInteraction: 1,
    })
  },

  globalSearchClicked() {
    trackEvent('Global_Search_Clicked', {
      page: window.location.pathname,
    })
  },
  imageLoadFailure(error) {
    trackEvent('Image_Load_Failure', {
      reason: error,
      page: window.location.pathname,
    })
  },

  procedureCostLoaded(values = {}) {
    trackEvent('Procedure_Cost_Loaded', {
      ...values,
      nonInteraction: 1,
    })
  },

  procedureRecoveryLoaded(values = {}) {
    trackEvent('Procedure_Recovery_Loaded', {
      ...values,
      nonInteraction: 1,
    })
  },

  chatWithSpecialist(slug) {
    trackEvent('Chat_With_Specialist_Clicked', {
      page: window.location.pathname,
      slug,
    })
  },

  thirdPartyPayClicked(vals) {
    trackEvent('Third_Party_Pay_Clicked', vals)
  },

  thirdPartyPayShown(payment_source = '') {
    trackEvent('Third_Party_Pay_Shown', {
      payment_source,
    })
  },

  webVitals(data) {
    trackEvent('Web_Vitals', data)
  },

  ecommCardClicked(data) {
    trackEvent('Ecomm_Card_Clicked', data)
  },

  ecommCardLoaded(data) {
    trackEvent('Ecomm_Card_Loaded', data)
  },

  editorialProcessPageLoaded() {
    trackEvent('Editorial_Process_Loaded', {
      nonInteraction: 1,
    })
  },
  editorialTeamPageLoaded() {
    trackEvent('Editorial_Team_Loaded', {
      nonInteraction: 1,
    })
  },

  experimentGoalClicked({ experiments }) {
    trackEvent('Experiment_Goal_Clicked', {
      experiments,
    })
  },

  tryOn3dBadgeClicked({ id, procedureName }) {
    trackEvent('3D_TryOn_Label_Clicked', {
      page: window.location.pathname,
      procedure_name: procedureName,
      id,
    })
  },

  morphingLoaded() {
    trackEvent('Morphing_Loaded')
  },

  inAppPurchase: {
    allAccessConcierge: {
      loaded({ source }) {
        trackEvent('IAP_AAC_Loaded', {
          type: source,
        })
      },
      valueAddLoaded({ source }) {
        trackEvent('IAP_AAC_Value_Add_Loaded', {
          type: source,
        })
      },
      transactionLoaded({ source }) {
        trackEvent('IAP_AAC_Transaction_Loaded', {
          type: source,
        })
      },
      confirmationLoaded({ source }) {
        trackEvent('IAP_AAC_Confirmation_Loaded', {
          type: source,
        })
      },
      welcomeBackLoaded({ source }) {
        trackEvent('IAP_Welcome_Back_Loaded', {
          type: source,
        })
      },

      purchaseClicked({ productName, cost, procedureId, procedureName, source }) {
        trackEvent('IAP_AAC_Purchase_Clicked', {
          page: window.location.pathname,
          product_name: productName,
          plan_cost: cost,
          entry_procedure_id: procedureId,
          entry_procedure_name: procedureName,
          type: source,
        })
      },

      submitPayment({ paymentSource, source }) {
        trackEvent('IAP_AAC_Submit_Payment', {
          type: source,
          payment_source: paymentSource,
        })
      },
      purchaseSuccess({ productName }) {
        trackEvent('IAP_AAC_Purchase_Success', {
          product_name: productName,
        })
      },

      dismissed() {
        trackEvent('IAP_AAC_Dismiss')
      },
      transactionDismissed() {
        trackEvent('IAP_AAC_Transaction_Dismiss')
      },
      confirmationDismissed() {
        trackEvent('IAP_AAC_Confirmation_Dismiss')
      },

      error({ code, message }) {
        trackEvent('IAP_AAC_Error', {
          error_code: code,
          error_message: message,
        })
      },
    },
  },
}
