import Geocode from 'services/geocode'
import { callApi } from 'services/config'
import { log } from 'services/util/log'
import { trackError } from 'services/util/error'
import { singleEntry } from 'services/util/abstract'

const locationEndpoint = '/location/whereami'

export const getLocationFromIp = singleEntry(async request => {
  try {
    const onClient = !!process.browser

    if (process.env.ENV_SETTINGS === 'development') {
      // fake it in dev (comment this whole section out if testing using VPN/ngrok)
      return {
        city: 'New York',
        country: 'US',
        state: 'NY',
        latitude: 40.7484,
        longitude: -73.9857,
        ip: '65.223.172.106',
      }
      // Use this to fake a request from Seattle
      // return {
      //   city: 'Seattle',
      //   country: 'US',
      //   state: 'WA',
      //   latitude: 47.6062,
      //   longitude: -122.3321,
      //   ip: '23.81.209.137',
      // }
    }

    if (onClient) {
      // we don't need to make an ip fetch first on client side
      // fire off whereami request with no params
      const res = await callApi('GET', locationEndpoint)

      if (res && res.state) {
        return res
      } else {
        return null
      }
    } else {
      // get the ip using 'request-ip' package
      const { default: requestIp } = await import('request-ip')
      const ip = await requestIp.getClientIp(request)
      if (ip) {
        // whereami request with ip params
        const res = await callApi('GET', locationEndpoint, { ip })

        if (res && res.state) {
          return { ...res, ip }
        } else {
          return null
        }
      } else {
        // no ip, so location is null
        return null
      }
    }
  } catch (err) {
    trackError('Failed to get location from IP', err)
  }

  return null
})

// feed it an address string and get lat/lng/etc back
const getLocationFromString = async str => {
  try {
    const response = await Geocode.fromAddress(str, process.env.GOOGLE_MAPS_API_KEY)
    const { lat, lng } = response.results[0].geometry.location
    let formattedAddress = response.results[0].formatted_address
      .split(',')
      .map(address => address.trim())

    return {
      city: formattedAddress[0],
      country: formattedAddress[2],
      state: formattedAddress[1],
      latitude: parseFloat(lat),
      longitude: parseFloat(lng),
    }
  } catch (e) {
    log('Failed to get location from string', 'error', { error: e, str })
  }
}

export { getLocationFromString }
