export const filterValid =
  (getDataFn = a => a) =>
  filterFn =>
  input =>
    getDataFn(input).filter(filterFn)

export const id = a => a

export const noop = () => undefined

export const not = a => !a

/**
 * Given an array of values, returns the first `value` for which `Boolean(value) === true`.
 *
 * @throws {Error} if `values` is not an array.
 */
export const firstTruthyValue = values => {
  if (!Array.isArray(values)) {
    throw new Error(`firstTruthyValue requires an array parameter, but got ${values}`)
  }
  return values.find(Boolean)
}

export const zip = (a, b, fillValue = undefined) => {
  const [longer, shorter, left] = a.length >= b.length ? [a, b, true] : [b, a, false]
  return longer.map((item, idx) =>
    left ? [item, shorter[idx] || fillValue] : [shorter[idx] || fillValue, item]
  )
}

export const round = (num, decimalPlaces, stripTrailingZeros = false) => {
  const q = Math.pow(10, decimalPlaces)
  const r = Math.round((num + Number.EPSILON) * q) / q
  const x = Number(r).toFixed(decimalPlaces)
  if (stripTrailingZeros) {
    return x.replace(/\.?0+$/, '')
  } else {
    return x
  }
}

export function roundTo(n, digits) {
  var negative = false
  if (digits === undefined) {
    digits = 0
  }
  if (n < 0) {
    negative = true
    n = n * -1
  }
  var multiplicator = Math.pow(10, digits)
  n = parseFloat((n * multiplicator).toFixed(11))
  n = (Math.round(n) / multiplicator).toFixed(digits)
  if (negative) {
    n = (n * -1).toFixed(digits)
  }
  return n
}

// export const scrollToBottom = bottomOf => {
//   bottomOf?.current.scrollTo({
//     top: bottomOf.current.scrollHeight - bottomOf.current.offsetHeight,
//     behavior: 'smooth',
//   })
// }

export const scrollParentToChild = (parent, child) => {
  parent?.current &&
    child?.current &&
    parent.current.scrollTo({
      top: child.current.offsetTop,
      behavior: 'smooth',
    })
}

const semaphores = {}
export const singleEntry = fn => {
  const name = fn.name
  return async (...args) => {
    let promise = semaphores[name]
    if (promise) {
      return await promise
    } else {
      try {
        promise = semaphores[name] = fn(...args)
        return await promise
      } finally {
        delete semaphores[name]
      }
    }
  }
}

/**
 * Invokes the provided function when the CPU goes idle
 *
 * @param {function} func Function to invoke
 * @param {?Object=} params
 * @param {?Number=} params.after Invoke the function no earlier than the specified of milliseconds
 * @param {?Number=} params.before Invoke the function no later than the specified of milliseconds
 */
export const onIdle = (func, params) => {
  const { after, before } = params ?? {}

  if (process.browser && window.requestIdleCallback) {
    setTimeout(() => {
      const options = {}
      if (typeof before !== 'undefined' && before !== null) {
        const timeout = before - (after ?? 1)
        options.timeout = Math.max(1, timeout) // must be a positive number
      }
      window.requestIdleCallback(func, options)
    }, after ?? 1)
  } else {
    setTimeout(() => {
      func()
    }, before ?? after ?? 1)
  }
}

export function alphabeticalSort(list = [], propertyToSortBy = '') {
  return (Array.isArray(list) ? list : []).sort((a, b) =>
    (a[propertyToSortBy] || '')
      .toLowerCase()
      .trim()
      .localeCompare((b[propertyToSortBy] || '').toLowerCase().trim())
  )
}

export const trim = (str, char) => {
  while (str.charAt(0) === char) {
    str = str.substring(1)
  }

  while (str.charAt(str.length - 1) === char) {
    str = str.substring(0, str.length - 1)
  }

  return str
}
